import React from "react"
import Layout from "../templates/Layout"
//import Slices from "../slices"
import "react-unique-key" 
import ArticleHero from "../slices/ArticleHero"
import PresentationList from "../slices/PresentationList"
import ThreeColBlockList from "../slices/ThreeColBlockList"
import TwoColBlockList from "../slices/TwoColBlockList"
import ThreeColGrid from "../slices/ThreeColGrid"
import Banner from "../slices/Banner"
import Helmet from "react-helmet"
import { graphql } from "gatsby"

const Article = ({ data: { prismicArticlepage } }) => {
  const { data } = prismicArticlepage;

  return (
    <Layout page={prismicArticlepage}>
      <Helmet>
          <script type="application/ld+json">{`
            {
                "@context": "http://schema.org",
                "@type": "WebPage",
                "name": "${data.microdata_name.text}",
                "description": "${data.microdata_description.text}"
            }
        `}</script>
      </Helmet>
      {/* <Slices data={data.body} /> */}
        {data.body.map((item, key) => {
          switch(item.slice_type){
            case "hero":
              return (
                <ArticleHero 
                  key={key}
                  primary={item.primary}
                  items={item.items}
                />
              )
            case "two_col_block_list":
              return (
                <TwoColBlockList 
                  key={item['unique_key']}
                  primary={item.primary}
                  items={item.items}
                />
              )
            case "banner":
              return (
                <Banner 
                  key={item['unique_key']}
                  primary={item.primary}
                />
            )  
            case "three_col_grid":
              return (
                <ThreeColGrid 
                  key={item['unique_key']}
                  primary={item.primary}
                  items={item.items}
                />
            )  
            case "presentation_list":
              return (
                <PresentationList
                  key={item['unique_key']}
                  primary={item.primary}
                  items={item.items}
                />
              )
            case "three_col_block_list":
              return (
                <ThreeColBlockList 
                  key={key}
                  primary={item.primary}
                  items={item.items}
                />
              )
        
            default:
              return (
                <pre key={key}>{JSON.stringify(item, null, 4)}</pre>
              )
            }
          }
        )}
    </Layout>
  )
}

export default Article
export const pageQuery = graphql`
  query ArticleBySlug($uid: String!) {
    prismicArticlepage(uid: { eq: $uid }) {
      uid
      data {
        body {
          __typename
          ... on PrismicArticlepageBodyHero {
            id
            items {
              item_image {
                url
              }
              item_title {
                html
                text
              }
            }
            primary {
              bg_color
              text_align
              text_color
              title {
                html
              }
              body_text {
                html
              }
              cta {
                slug
                isBroken
              }
              image {
                alt
                localFile {
                  childImageSharp {
                      fluid(quality: 100, maxWidth: 1920) {
                      ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                }
              }
            }
          slice_type
          }
          ... on PrismicArticlepageBodyTwoColBlockList {
            id
            slice_type
            primary {
              bg_color
              text_color
              show_item_icons
              show_body_icons
              image {
                alt
                url
                dimensions {
                  height
                  width
                }
                localFile {
                  childImageSharp {
                      fluid(quality: 100, maxWidth: 300) {
                      ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                }
              }
              title {
                html
              }
              body_text {
                html
              }
              cta {
                isBroken
                slug
              }
            }
            items {
              item_text {
                html
              }
              item_icon {
                url
                alt
              }
              item_title {
                html
              }
            }
          }
          ... on PrismicArticlepageBodyThreeColBlockList {
            id
            slice_type
            primary {
              bg_image_place
              text_color
              clip
              body_text {
                html
              }
              cta {
                slug
                isBroken
              }
              title {
                html
              }
              bg_color
              image {
                alt
                url
                localFile {
                  childImageSharp {
                      fluid(quality: 100, maxWidth: 1280) {
                      ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                }
              }
            }
            items {
              item_icon {
                url
                alt
              }
              item_text {
                html
              }
              item_title {
                html
              }
            }
          }
          ... on PrismicArticlepageBodyPresentationList {
            id
            primary {
              bg_color
              is_numbered
              show_body_icons
              text_color
              title {
                html
              }
              body_text {
                html
              }
              cta {
                isBroken
                slug
              }
              image {
                alt
                localFile {
                  childImageSharp {
                      fluid(quality: 100, maxWidth: 1280) {
                      ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                }
              }
            }
            slice_type
            items {
              item_image {
                url
              }
              item_text {
                html
              }
              item_title {
                html
              }
            }
          }
          ... on PrismicArticlepageBodyTwoColBlockList {
            id
            slice_type
            primary {
              bg_color
              text_color
              show_item_icons
              show_body_icons
              image {
                alt
                url
                dimensions {
                  height
                  width
                }
                localFile {
                  childImageSharp {
                      fluid(quality: 100, maxWidth: 300) {
                      ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                }
              }
              title {
                html
              }
              body_text {
                html
              }
              cta {
                isBroken
                slug
              }
            }
            items {
              item_text {
                html
              }
              item_icon {
                url
                alt
              }
              item_title {
                html
              }
            }
          }
          ... on PrismicArticlepageBodyBanner {
            slice_type
            primary {
              banner_image {
                url
                alt
                localFile {
                  childImageSharp {
                      fluid(quality: 100, maxWidth: 1920) {
                      ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                }
              }
              creator_link {
                url
              }
              creator {
                text
              }
            }
          }
          ... on PrismicArticlepageBodyThreeColGrid {
            id
            slice_type
            primary {
              bg_color
              show_body_icons
              show_item_icons
              text_color
              title {
                html
              }
              body_text {
                html
              }
              image {
                alt
                url
                localFile {
                  childImageSharp {
                      fluid(quality: 100, maxWidth: 1280) {
                      ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                }
                dimensions {
                  height
                  width
                }
              }
            }
            items {
              item_icon {
                url
              }
              item_text {
                html
              }
              item_title {
                html
              }
            }
          }

        }
        meta_description {
          text
        }
        meta_title {
          text
        }
        microdata_description {
          text
        }
        microdata_name {
          text
        }
      }
    }
  }
`