import React from "react"
import Button from "../components/button"
import Img from "gatsby-image";
import FormattedText from "../components/FormattedText"

const PresentationList = ({ items, primary}) => {
  //style={{background: primary.bg_color, color: primary.text_color}}
  return (
    <section className="py-24" style={{background: primary.bg_color, color: primary.text_color}}>
      <div className="container relative">
        <div className="lg:flex lg:text-left text-center">
          <div className="flex-1 lg:pr-16 mb-16 lg:mb-0 sticky">

            {primary.image.localFile &&
              <figure className="mb-16">
                <Img className="w-full mx-auto" fluid={primary.image.localFile.childImageSharp.fluid} alt={primary.image.alt} />
              </figure>
            }
            
            <div className="mb-4 big-title">
              <FormattedText text={primary.title.html} />
            </div>
            <div className="mb-4">
              <FormattedText text={primary.body_text.html} />
            </div>
            
            <Button text="Start a project" isDark={primary.bg_color === "#000000"} />
            
          </div>
          
          <div className="flex-1">
            {items.map((item, key) => {
              return (
                <div key={key} className="mb-12">
                  <div className="flex">
                    {primary.is_numbered ?
                      <div>
                        <div className="h-12 w-12 pt-3 border rounded-full text-center hidden lg:block">
                          {key + 1}
                        </div>
                        <div 
                          className="border w-0 h-full ml-6 hidden lg:block"
                          style={{
                            borderWidth: "0.5px", 
                            visibility: key + 1 === items.length ? "hidden" : "visible"
                          }} />
                      </div>
                      :
                      <div className="lg:border-l-2 border-gray-400"/>
                    }
                    
                    <div className="lg:pl-4">
                      <FormattedText text={item.item_title.html} />
                      <FormattedText text={item.item_text.html} />
                      
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )

}

export default PresentationList;