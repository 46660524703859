import React from 'react';
import Img from "gatsby-image";

const Banner = ({primary}) => {
  return (
    <figure>
      <Img className="mx-auto h-auto" fluid={primary.banner_image.localFile.childImageSharp.fluid} alt={primary.banner_image.alt} />
      {/* <div className="container relative">
        <figcaption className="absolute">
          <span>Creator</span>
        </figcaption>
      </div> */}
    </figure>
  );
};

export default Banner;